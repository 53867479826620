<template>
  <div class="user">
    <NavBar />
    <v-container fluid>
      <h1>Пользователи</h1>

      <v-row>
        <v-col cols="12" md="12" xl="11" class="px-4 px-sm-8 pl-md-12 pt-0">
          <v-row class="my-2">
            <v-col>
              <UserCreateDialog />
            </v-col>
          </v-row>
          <v-data-table :headers="headers" :items="userList" disable-pagination hide-default-footer>
            <template v-slot:item.isActive="{ item }">
              {{ activeProperty(item.disabled) }}
            </template>
            <template v-slot:item.action="{ item }">
              <UserEditDialog :user="item" />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar';
import UserCreateDialog from '@/components/user/UserCreateDialog';
import UserEditDialog from '@/components/user/UserEditDialog';

import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';

export default {
  data() {
    return {
      headers: [
        { text: 'Имя пользователя', value: 'fio' },
        { text: 'Логин', value: 'username' },
        { text: 'Описание', value: 'description' },
        { text: 'Забокирован', value: 'isActive' },
        // { text: 'Забанен', value: 'disabledBySuperior' },
        { text: '', value: 'action' }
      ]
    };
  },
  components: {
    UserCreateDialog,
    UserEditDialog,
    NavBar
  },
  computed: {
    ...mapGetters('user', ['userList', 'userData', 'roleList'])
  },
  methods: {
    activeProperty(property) {
      return ReferenceHelper.getActivePropertyValue(property);
    },
    fetchUserList() {
      this.getUsers();
    },
    ...mapActions('user', ['getUsers']),
    ...mapActions('user', ['editUser'])
  },
  mounted() {
    this.fetchUserList();
  }
};
</script>
