const ACTIVE_PROPERTY = [
  { value: true, text: 'Да' },
  { value: false, text: 'Нет' }
];
const DEFAULT_PAGE_LIST = [
  { value: 'user', text: 'Пользователи' },
  { value: 'reference', text: 'Справки' },
  { value: 'escort', text: 'Сопровождение' },
  { value: 'food', text: 'Бистро' }
];
export default {
  getDisabledPropertyList,
  getDisabledPropertyValue,
  getDefaultPageList,
  getDefaultPageValue
};
// function userRoles() {
//   return USER_ROLES_LIST;
// }

function getDisabledPropertyList() {
  return ACTIVE_PROPERTY;
}
function getDisabledPropertyValue(value) {
  return getTextByValue(ACTIVE_PROPERTY, value);
}
function getDefaultPageList() {
  return DEFAULT_PAGE_LIST;
}
function getDefaultPageValue(value) {
  return getTextByValue(DEFAULT_PAGE_LIST, value);
}
function getTextByValue(list, value) {
  let name = value;
  for (let i = 0; i < list.length; i++) {
    if (list[i].value === value) {
      name = list[i].text;
      break;
    }
  }
  return name;
}
