<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on }">
      <v-btn color="red" text v-on="on">
        <v-icon left>mdi-plus</v-icon>
        Добавить пользователя
      </v-btn>
    </template>
    <v-card class="px-2 pt-1 pb-3" outlined>
      <v-form ref="form" @submit.prevent="checkInput">
        <v-card-text>
          <span>Логин</span>
          <v-text-field v-model="user.username" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
          <div class="mt-5">Пароль</div>
          <v-text-field v-model="user.password" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
          <div class="mt-5">ФИО</div>
          <v-text-field v-model="user.fio" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
          <div class="mt-5">Cтартовая страница</div>
          <v-select v-model="user.defaultPage" :items="getDefaultPageItems()" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
          <div class="mt-5">Описание</div>
          <v-text-field v-model="user.description" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
          <div class="mt-5">Почта</div>
          <v-text-field v-model="user.mail" :rules="rules" class="mt-2" dense hide-details="auto" outlined />
          <!-- <div class="mt-5">Телефон</div>
          <v-text-field v-model="user.phone" :rules="rules" class="mt-2" dense hide-details="auto" outlined /> -->
          <!-- <div class="mt-5">Стартовая страница</div>
          <v-select :items="userRolesItems()" v-model="user.defaultPage" :rules="rules" class="mt-2" dense hide-details="auto" outlined /> -->
          <!-- <div class="mt-5">Роли пользователя</div>
          <v-select
            :items="userRolesItems()"
            item-value="key"
            item-text="desc"
            v-model="roles"
            :rules="rules"
            class="mt-2"
            dense
            hide-details="auto"
            outlined
            return-object
          /> -->
        </v-card-text>
        <v-card-actions class="mt-6">
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="red" text>Отмена</v-btn>
          <v-btn :loading="loading" @click="addUserClick" color="red" class="white--text" depressed>Добавить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import UserHelper from '@/helpers/user.helper';

import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      user: {
        username: '',
        password: '',
        fio: '',
        description: '',
        defaultPage: '',
        mail: ''
      },
      loading: false,
      dialog: false,
      // roles: {},
      rules: [(v) => !!v || 'Обязательное поле']
    };
  },
  computed: {
    ...mapGetters('user', ['result']),
    ...mapGetters('user', ['userData']),
    ...mapGetters('user', ['roleList'])
  },
  methods: {
    getDefaultPageItems() {
      return UserHelper.getDefaultPageList();
    },
    checkInput() {
      return true;
    },
    // userRolesItems() {
    //   return ReferenceHelper.userRoles();
    // },
    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.user.username = '';
      this.user.password = '';
      this.user.fio = '';
      this.user.description = '';
      this.user.defaultPage = '';
      this.user.mail = '';
      // this.roles = {};
      this.getUsers();
    },
    addUserClick() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.addUser(this.user)
          .then(() => {
            // if (this.result) {
            //   this.roles.id = this.userData.id;
            //   this.addRoles(this.roles).then(() => {
            //     if (this.result) {
            this.closeDialog();
            //     }
            //   });
            // }
          })
          .catch(() => {
            this.closeDialog();
          });
      }
    },
    ...mapActions('user', ['addUser']),
    ...mapActions('user', ['addRoles']),
    ...mapActions('user', ['getUsers'])
  }
};
</script>
