const Stations = require('./stations.json');
const Fields = require('./fields.json');
const REFERENCE_TYPES = {
  1: 'Стоимость проезда',
  2: 'Утерянный документ',
  3: 'Наличие мест',
  4: 'Кратчайший маршрут',
  5: 'Изменение движения'
};
const TARIFF_LIST = [
  { value: 'ПОЛНЫЙ', text: 'Полный тариф' },
  { value: 'ДЕТСКИЙ', text: 'Детский тариф' },
  { value: 'ШКОЛЬНЫЙ', text: 'Школьный тариф' }
];
const CAR_LIST = [
  { value: 'ПЛАЦКАРТ', text: 'ПЛАЦКАРТ' },
  { value: 'КУПЕ', text: 'КУПЕ' },
  { value: 'ЛЮКС', text: 'ЛЮКС' },
  { value: 'СВ', text: 'СВ' },
  { value: 'ОБЩИЙ', text: 'ОБЩИЙ' },
  { value: 'СИДЯЧИЙ-БИЗНЕС', text: 'СИДЯЧИЙ-БИЗНЕС' },
  { value: 'СИДЯЧИЙ-ЭКОНОМ', text: 'СИДЯЧИЙ-ЭКОНОМ' }
];
const SEAT_LIST = [
  { value: 'БН', text: 'Без указания конкретного места' },
  { value: 'И', text: 'Места для инвалидов' }
];
const ORDER_STATUS = {
  1: 'Заявка создана, ожидается оплата',
  2: 'Оплата не выполнена',
  3: 'Оплата выполнена, ожидается ответ от экспресса',
  4: 'Ожидается обработка оператором',
  5: 'Заявка обработана оператором, ожидается отправка справки на почту',
  6: 'Заявка завершена, справка отправлена на почту',
  100: 'Заявка отменена'
};
const ACTION_NAME = [
  { value: 1, text: 'ОТМЕНА ЗАКАЗА' },
  { value: 2, text: 'ОПЛАТА ЗАКАЗА' },
  { value: 3, text: 'РЕЗЕРВИРОВАНИЕ' },
  { value: 4, text: 'ФОРМИРОВАНИЕ PDF' },
  { value: 5, text: 'ОТПРАВКА PDF' },
  { value: 6, text: 'ЗАВЕРШЕНИЕ ЗАКАЗА' }
];
const PAYMENT_STATUS = {
  1: 'Платёж создан',
  2: 'Платёж отклонён',
  3: 'Платёж подтверждён'
};
const TRAIN_LIST = [
  { value: 'ВЫСОКОСКОРОСТНОЙ', text: 'ВЫСОКОСКОРОСТНОЙ' },
  { value: 'ФИРМЕННЫЙ', text: 'ФИРМЕННЫЙ' },
  { value: 'СКОРЫЙ', text: 'СКОРЫЙ' },
  { value: 'ПАССАЖИРСКИЙ', text: 'ПАССАЖИРСКИЙ' },
  { value: 'СКОРОСТНОЙ', text: 'СКОРОСТНОЙ' }
];
const RETURN_LIST = [
  { value: 'Производился', text: 'Производился' },
  { value: 'Не производился', text: 'Не производился' }
];
const DAY_LIST = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
const USER_ROLES_LIST = [
  {
    key: 'admin',
    desc: 'Администратор',
    value: 1
  }
  // {
  //   key: 'operator',
  //   desc: 'Оператор',
  //   value: 1
  // },
  // {
  //   key: 'client',
  //   desc: 'Клиент',
  //   value: 1
  // }
];
const ACTIVE_PROPERTY = [
  { value: true, text: 'Да' },
  { value: false, text: 'Нет' }
];
const DEFAULT_PAGE_LIST = [
  { value: 'operator', text: 'Оператор' },
  { value: 'reference', text: 'Справки' }
];
export default {
  userRoles,
  getStationList,
  getTariffTypeList,
  getCarTypeList,
  getSeatTypeList,
  getReferenceTypeName,
  checkFioCharacters,
  declOfNum,
  getStationName,
  getTariffName,
  getCarName,
  getSeatName,
  getOrderStatus,
  getTrainTypeList,
  getFields,
  getTrainName,
  getDateList,
  getreturnList,
  getActivePropertyList,
  getActivePropertyValue,
  getDefaultPageList,
  getDefaultPageValue,
  getActionTypeByValue
};
function getActivePropertyList() {
  return ACTIVE_PROPERTY;
}
function getActivePropertyValue(value) {
  return getTextByValue(ACTIVE_PROPERTY, value);
}

function getDefaultPageList() {
  return DEFAULT_PAGE_LIST;
}
function getDefaultPageValue(value) {
  return getTextByValue(DEFAULT_PAGE_LIST, value);
}
function getReferenceTypeName(referenceType) {
  return REFERENCE_TYPES[referenceType] || 'Тип: ' + referenceType;
}
function getDateList() {
  return DAY_LIST;
}
function getreturnList() {
  return RETURN_LIST;
}
function checkFioCharacters(fio) {
  const exp = new RegExp('[^а-яА-Яa-zA-Z-ёЁ]+');
  return !exp.test(fio);
}
function declOfNum(n, text_forms) {
  n = Math.abs(n) % 100;
  let n1 = n % 10;
  if (n > 10 && n < 20) {
    return text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1];
  }
  if (n1 === 1) {
    return text_forms[0];
  }
  return text_forms[2];
}
function userRoles() {
  return USER_ROLES_LIST;
}
function getStationList() {
  const stationList = [];
  for (let i = 0; i < Stations.length; i++) {
    stationList.push({ value: Stations[i].code, text: Stations[i].name });
  }
  return stationList;
}
function getFields() {
  return JSON.parse(JSON.stringify(Fields));
}
function getActionTypeByValue(value) {
  return getTextByValue(ACTION_NAME, value);
}
function getTariffTypeList() {
  return TARIFF_LIST;
}
function getCarTypeList() {
  return CAR_LIST;
}
function getSeatTypeList() {
  return SEAT_LIST;
}
function getTrainTypeList() {
  return TRAIN_LIST;
}
function getOrderStatus(value) {
  return ORDER_STATUS[value] || 'Код ' + value;
}
function getStationName(value) {
  return getTextByValue(getStationList(), value);
}
function getTariffName(value) {
  return getTextByValue(TARIFF_LIST, value);
}
function getCarName(value) {
  return getTextByValue(CAR_LIST, value);
}
function getSeatName(value) {
  return getTextByValue(SEAT_LIST, value);
}
function getTrainName(value) {
  return getTextByValue(TRAIN_LIST, value);
}
function getTextByValue(list, value) {
  let name = value;
  for (let i = 0; i < list.length; i++) {
    if (list[i].value === value) {
      name = list[i].text;
      break;
    }
  }
  return name;
}
