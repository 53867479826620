<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <template v-slot:activator="{ on }">
      <v-btn color="red" text v-on="on">
        <v-icon left>mdi-plus</v-icon>
        Подробнее
      </v-btn>
    </template>

    <v-stepper class="mt-12" non-linear
      ><v-stepper-header>
        <v-stepper-step editable step="1"> Редактирование поьзователя </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step editable step="2"> Редактирование роли </v-stepper-step>
      </v-stepper-header>
      <v-stepper-content step="1">
        <!-- <v-card class="px-2 pt-1 pb-3" outlined> -->
        <v-form ref="form" @submit.prevent="checkInput">
          <v-card-title class="text-h5 font-weight-bold">Редактирование пользователя</v-card-title>
          <v-card-text class="mt-1">
            <v-row no-gutters class="RowField" v-for="item in getUserEditFields" :key="item.id">
              <v-col>
                <div class="mt-5 mb-2">{{ item.name }}</div>
                <v-text-field
                  v-if="item.type !== 'defaultPage' && item.type !== 'disabled' && item.type !== 'permissions'"
                  dense
                  hide-details="auto"
                  outlined
                  :label="editedUser[item.in]"
                  v-model="modifyUser[item.out]"
                  class="custom-label-color"
                ></v-text-field>
                <v-select
                  v-if="item.type === 'defaultPage'"
                  dense
                  hide-details="auto"
                  outlined
                  v-bind:label="getDefaultPageName(editedUser[item.in])"
                  :items="getDefaultPageItems(item.in)"
                  v-model="modifyUser[item.out]"
                  class="custom-label-color"
                ></v-select>
                <!-- <v-select
                    v-if="item.type === 'isActive'"
                    dense
                    hide-details="auto"
                    outlined
                    :items="getBooleanItems(item.in)"
                    v-bind:label="getBooleanName(editedUser[item.in])"
                    v-model="modifyUser[item.out]"
                    class="custom-label-color"
                  ></v-select> -->
                <v-select
                  v-if="item.type === 'disabled'"
                  dense
                  hide-details="auto"
                  outlined
                  :items="getBooleanItems(item.in)"
                  v-bind:label="getBooleanName(editedUser[item.in])"
                  v-model="modifyUser[item.out]"
                  class="custom-label-color"
                ></v-select>
                <!-- <v-select
                v-if="item.type === 'permissions'"
                dense
                hide-details="auto"
                outlined
                :items="getPermissionItems(item.in)"
                v-bind:label="getPemissionName(editedUser[item.in])"
                v-model="modifyUser[item.out]"
                class="custom-label-color"
              ></v-select> -->
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-6">
            <v-spacer></v-spacer>
            <v-btn @click="closeDialog" color="red" text>Отмена</v-btn>
            <v-btn :loading="loading" type="submit" color="red" depressed>Добавить</v-btn>
          </v-card-actions>
        </v-form>
        <!-- </v-card> -->
      </v-stepper-content>
      <v-stepper-content step="2">
        <!-- <v-card> -->
        <v-card-text>
          <!-- {{ roles }} -->
          <div class="d-flex">
            Роли:
            <div v-for="item in Object.keys(user.permission)" :key="item">{{ item }},</div>
          </div>
          <v-select
            :items="userRolesItems()"
            item-value="key"
            item-text="desc"
            v-model="roles"
            :rules="rules"
            class="mt-2"
            dense
            hide-details="auto"
            outlined
            return-object
          />
        </v-card-text>
        <v-card-actions class="mt-6">
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="red" text>Отмена</v-btn>
          <v-btn :loading="loading" @click="editRole" color="red" depressed>Добавить</v-btn>
        </v-card-actions>
        <!-- </v-card> -->
      </v-stepper-content>
    </v-stepper>
  </v-dialog>
</template>
<script>
import userFields from '@/helpers/userFields.json';
import UserHelper from '@/helpers/user.helper';

import ReferenceHelper from '@/helpers/reference/reference.helper';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      // editedUser: {},
      modifyUser: {},
      rules: [(v) => !!v || 'Обязательное поле'],
      loading: false,
      dialog: false,
      roles: {}
    };
  },
  props: {
    user: {
      type: Object
    }
  },
  computed: {
    editedUser() {
      return JSON.parse(JSON.stringify(this.user));
    },
    getUserEditFields() {
      return { ...JSON.parse(JSON.stringify(userFields))[1] };
    }
  },
  methods: {
    getBooleanName: ReferenceHelper.getActivePropertyValue,
    getDefaultPageName: UserHelper.getDefaultPageValue,
    getDefaultPageItems() {
      return UserHelper.getDefaultPageList();
    },
    getBooleanItems() {
      return ReferenceHelper.getActivePropertyList();
    },
    userRolesItems() {
      return ReferenceHelper.userRoles();
    },
    checkInput() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.submitData();
      }
    },
    submitData() {
      this.modifyUser.id = this.editedUser.id;
      this.editUser(this.modifyUser)
        .then(() => {
          this.closeDialog();
        })
        .catch(() => {
          this.closeDialog();
        });
    },
    editRole() {
      this.roles.id = this.user.id;
      this.addRoles(this.roles)
        .then(() => {
          this.closeDialog();
        })
        .catch(() => {
          this.closeDialog();
        });
    },
    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.editedUser = {};
      this.modifyUser = {};
      this.roles = {};
      this.getUsers();
    },
    ...mapActions('user', ['editUser']),
    ...mapActions('user', ['addRoles']),
    ...mapActions('user', ['getUsers'])
  },
  mounted() {
    this.editedUser = this.user;
    Object.keys(this.user.permission).forEach((key) => {
      this.roles.key = key;
      this.roles.value = 1;
    });
  }
};
</script>
